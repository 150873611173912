import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import InsideApi from '../../services/InsideApi';

const initialState = {
  error: null,
  clientes: [],
  loading: false,
};

export const listarClientes = createAsyncThunk("/listar_clientes", async () => {
    let res = await InsideApi.listarClientes();
    return res;
});

const clienteSlice = createSlice({
  initialState,
  name: "clientes",
  extraReducers: (builder) => {
    builder
      .addCase(listarClientes.pending, (state) => {
        state.loading = true;
        state.clientes = [];
        state.error = null;
      })
      .addCase(listarClientes.fulfilled, (state, actions) => {        
        state.loading = false;
        state.clientes = actions.payload;
        state.error = null;
      })
      .addCase(listarClientes.rejected, (state, actions) => {
        state.loading = false;
        state.clientes = [];
        state.error = actions.error.message;
      });
  },
});

export const clienteReducer = clienteSlice.reducer;
