import axios from 'axios';
import { configuracao } from 'config';

const api = axios.create({
    baseURL: configuracao.api.baseUrl + '/v100',
    responseType: 'json',
    responseEncoding: 'utf8',
});

export default api;

