import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaChamados = Loadable(lazy(() => import('./ListaChamados')));
const Chamado = Loadable(lazy(() => import('./Chamado')));

const chamadosRoutes = [
    { path: '/chamados', element: <ListaChamados /> },
    { path: '/chamados/chamado/', element: <Chamado /> },
    { path: '/chamados/chamado/:id', element: <Chamado /> }
];

export default chamadosRoutes;