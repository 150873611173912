import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const Filas = Loadable(lazy(() => import('./Filas')));
const Fila = Loadable(lazy(() => import('./Fila')));

const filasRoutes = [
  { path: '/filas', element: <Filas /> },
  { path: '/fila', element: <Fila /> },
  { path: '/fila/:id', element: <Fila /> }
];

export default filasRoutes;
