import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ListaClientes = Loadable(lazy(() => import('./ListaClientes')));
const Cliente = Loadable(lazy(() => import('./Cliente')));
const ClienteContrato = Loadable(lazy(() => import('./ClienteContrato')));
const ClienteArquivo = Loadable(lazy(() => import('./ClienteArquivo')));
const ClienteAnotacao = Loadable(lazy(() => import('./ClienteAnotacao')));

const clientesRoutes = [
    { path: '/clientes', element: <ListaClientes /> },
    { path: '/clientes/cliente/:clienteId', element: <Cliente /> },
    { path: '/clientes/cliente/:clienteId/contrato', element: <ClienteContrato /> },
    { path: '/clientes/cliente/:clienteId/anotacao', element: <ClienteAnotacao /> },
    { path: '/clientes/cliente/:clienteId/arquivo', element: <ClienteArquivo /> }
];

export default clientesRoutes;