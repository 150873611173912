import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaCategoriasLancamentos = Loadable(lazy(() => import('./ListaCategoriasLancamentos')));
const FormularioCategoriasLancamentos = Loadable(lazy(() => import('./FormularioCategoriasLancamentos')));

const categoriasLancamentosRoutes = [
    { path: '/categorias-lancamentos', element: <ListaCategoriasLancamentos /> },
    { path: '/categorias-lancamentos/new', element: <FormularioCategoriasLancamentos /> },
    { path: '/categorias-lancamentos/:id', element: <FormularioCategoriasLancamentos /> },
];

export default categoriasLancamentosRoutes;