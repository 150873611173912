import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AuthProvider } from 'app/contexts/FirebaseAuthContext';
import { AuthProvider } from 'app/contexts/JWTAuthContext';
import { SettingsProvider } from 'app/contexts/SettingsContext';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import '../fake-db';
import { MatxTheme } from './components';
import UsuariosContextProvider from './providers/UsuariosContextProvider';
import ClientesContextProvider from 'app/providers/ClientesContextProvider';
import store from './redux/store';
import routes from './routes';
import AplicativoContextProvider from './providers/AplicativoContextProvider';
import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import './App.css';
import PrivilegiosContextProvider from './providers/PrivilegiosContextProvider';
import { AlertProvider } from './contexts/AlertContext';

const App = () => {
    const content = useRoutes(routes);

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale='pt-br'
        >
            <AplicativoContextProvider>
                <UsuariosContextProvider>
                    <PrivilegiosContextProvider>
                        <ClientesContextProvider>
                            <Provider store={store}>
                                <SettingsProvider>                                    
                                    <MatxTheme>
                                        <AuthProvider>
                                            <AlertProvider>
                                                {content}
                                            </AlertProvider>
                                        </AuthProvider>
                                    </MatxTheme>
                                </SettingsProvider>
                            </Provider>
                        </ClientesContextProvider>
                    </PrivilegiosContextProvider>
                </UsuariosContextProvider>
            </AplicativoContextProvider>
        </LocalizationProvider>
    );
};

export default App;
