import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Home = Loadable(lazy(() => import('./Home')));
const Estabelecimentos = Loadable(lazy(() => import('./estabelecimento/Estabelecimentos')));
const Estabelecimento = Loadable(lazy(() => import('./estabelecimento/Estabelecimento')));
const Integrador = Loadable(lazy(() => import('./Integrador')));
const ConferenciaIntegracao = Loadable(lazy(() => import('./ConferenciaIntegracao')));
const ConferenciaIntegracaoDetalhes = Loadable(lazy(() => import('./ConferenciaIntegracaoDetalhes')));
// const Faq1 = Loadable(lazy(() => import('./faq/Faq1')));
// const Faq2 = Loadable(lazy(() => import('./faq/Faq2')));
// const OrderList = Loadable(lazy(() => import('./orders/OrderList')));
// const UserList2 = Loadable(lazy(() => import('./user-list/UserList2')));
// const UserList3 = Loadable(lazy(() => import('./user-list/UserList3')));
// const UserList4 = Loadable(lazy(() => import('./user-list/UserList4')));
// const ProductList = Loadable(lazy(() => import('./products/ProductList')));
// const ProductForm = Loadable(lazy(() => import('./products/ProductForm')));
// const ListaClientes = Loadable(lazy(() => import('./clientes/ListaClientes')));
const ListaContratos = Loadable(lazy(() => import('./contratos/ListaContratos')));
// const Cliente = Loadable(lazy(() => import('./clientes/Cliente')));
const Contrato = Loadable(lazy(() => import('./contratos/Contrato')));
// const ProductViewer = Loadable(lazy(() => import('./products/ProductViewer')));
// const CustomerForm = Loadable(lazy(() => import('./customers/customer-form/CustomerForm')));

const pagesRoutes = [
  { path: '/home', element: <Home /> },
  { path: '/estabelecimentos', element: <Estabelecimentos />},
  { path: '/estabelecimento/:id', element: <Estabelecimento />},
  // { path: '/pages/user-list-2', element: <UserList2 /> },
  // { path: '/pages/user-list-3', element: <UserList3 /> },
  // { path: '/pages/user-list-4', element: <UserList4 /> },
  // { path: '/pages/faq-1', element: <Faq1 /> },
  // { path: '/pages/faq-2', element: <Faq2 /> },
  { path: '/contratos', element: <ListaContratos /> },
  { path: '/contratos/contrato/:id', element: <Contrato /> },
  { path: '/integrador/raiogps', element: <Integrador /> },  
  { path: '/integrador/conferencia', element: <ConferenciaIntegracao /> },
  { path: '/integrador/conferencia/:id', element: <ConferenciaIntegracaoDetalhes /> },
  // { path: '/clientes', element: <ListaClientes /> },
  // { path: '/clientes/cliente/:id', element: <Cliente /> },
  // { path: '/clientes/cliente/:id/Anotacao', element: <ClienteAnotacao /> },
  // { path: '/pages/new-customer', element: <CustomerForm /> },
  // { path: '/pages/product-list', element: <ProductList /> },
  // { path: '/pages/new-product', element: <ProductForm /> },
  // { path: '/pages/view-product', element: <ProductViewer /> },
  // { path: '/pages/order-list', element: <OrderList /> },
];

export default pagesRoutes;
