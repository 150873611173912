import axios from 'axios';
import api from './api';

const instance = axios.create({
    baseURL: process.env.REACT_APP_INSIDE_API_BASE_URL,
    redirect: 'follow',
});

class InsideApi {
    static getSecurityToken = async (user, passwd) => {
        try {
            const body = {
                'username': user,
                'password': passwd,
                'grant_type': 'password',
                'project': 'Raio'                
            }

            const headers = {
                'Content-Type': 'application/x-www-form-urlencoded',
            }

            const retorno = await instance.post('/security/token', body, headers);
            
            console.log('Retorno: ', retorno);
        } catch (e) {
            console.error('Erro ao requisitar o serviço web da Inside: ', e.message);
        }
    }

    static listarClientes = async () => {
        // let requestOptions = {
        //     method: 'POST',
        //     url: 'http://raiorastreadores.ddns.net:9494/api/security/token',
        //     redirect: 'follow',
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //     },
        //     data: {
        //         'username': '13294',
        //         'password': '123456',
        //         'grant_type': 'password',
        //         'project': 'Raio'
        //     }
        // };

        // let res = await axios(requestOptions)
        //     .then(async (response) => {
        //         let token = response.data;                
        //         let requestOptions = {
        //             method: 'GET',
        //             url: 'http://raiorastreadores.ddns.net:9494/api/listar_clientes',
        //             redirect: 'follow',
        //             headers: {
        //                 'Authorization': `${token.token_type} ${token.access_token}`
        //             },
        //             // headers: requestHeaders,
        //             params: {
        //                 'tipo': 'C'
        //             }
        //         };
        
        //         let res = await axios(requestOptions)
        //             .then((response) => response.data)
        //             .catch((error) => console.log(error));

        //         return res;
        //     })
        //     .catch((error) => console.log(error));

        try {
            const res = await api.get('/Inside/Cliente/Lista');
            // console.log(res);
            return res.data;
        } catch (e) {
            console.error(e);
        }
    };

    static listarVeiculos = async (id) => {
        // let requestOptions = {
        //     method: 'POST',
        //     url: 'http://raiorastreadores.ddns.net:9494/api/security/token',
        //     redirect: 'follow',
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //     },
        //     data: {
        //         'username': '13294',
        //         'password': '123456',
        //         'grant_type': 'password',
        //         'project': 'Raio'
        //     }
        // };

        // let res = await axios(requestOptions)
        //     .then(async (response) => {
        //         let token = response.data;
        //         console.log('response:');
        //         console.log(response);                
        //         let requestOptions = {
        //             method: 'GET',
        //             url: 'http://raiorastreadores.ddns.net:9494/api/listar_veiculos',
        //             redirect: 'follow',
        //             headers: {
        //                 'Authorization': `${token.token_type} ${token.access_token}`
        //             },
        //             // headers: requestHeaders,
        //             params: {
        //                 'codCliente': id
        //             }
        //         };
        
        //         let res = await axios(requestOptions)
        //             .then((response) => response.data)
        //             .catch((error) => console.log(error));

        //         // console.log(res);

        //         return res;
        //     })
        //     .catch((error) => console.log(error));
        try {
            const res = await api.get(`/Inside/Cliente/${id}/Veiculo/Lista`);
            return res.data;
        } catch (e) {
            console.error(e);
        }
    }
}

export default InsideApi;