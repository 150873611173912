import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const TicketsAbono = Loadable(lazy(() => import('./TicketsAbono')));
const TicketAbono = Loadable(lazy(() => import('./TicketAbono')));

const ticketsAbonoRoutes = [
    { path: '/tickets-abono', element: <TicketsAbono /> },
    { path: '/ticket-abono', element: <TicketAbono /> },
    { path: '/ticket-abono/:id', element: <TicketAbono /> }
];

export default ticketsAbonoRoutes;
