import { useState } from 'react';

import api from '../services/api';

import UsuariosContext from '../contexts/UsuariosContext';

const UsuariosContextProvider = ({ children }) => {
    const [ usuarios, setUsuarios ] = useState([]);

    return (
        <UsuariosContext.Provider value={{ usuarios, setUsuarios }}>
            {children}
        </UsuariosContext.Provider>
    );
};

export default UsuariosContextProvider;