import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const Servicos = Loadable(lazy(() => import('./Servicos')));
const Servico = Loadable(lazy(() => import('./Servico')));

const servicosRoutes = [
  { path: '/servicos', element: <Servicos /> },
  { path: '/servico', element: <Servico /> },
  { path: '/servico/:id', element: <Servico /> }
];

export default servicosRoutes;
