import { useState } from 'react';
import PrivilegiosContext from '../contexts/PrivilegiosContext';

const PrivilegiosContextProvider = ({ children }) => {
    const [ privilegios, setPrivilegios ] = useState([]);

    return (
        <PrivilegiosContext.Provider value={{ privilegios, setPrivilegios }}>
            {children}
        </PrivilegiosContext.Provider>
    );
};

export default PrivilegiosContextProvider;