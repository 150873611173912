import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaFeriados = Loadable(lazy(() => import('./ListaFeriados')));
const FormularioFeriados = Loadable(lazy(() => import('./FormularioFeriados')));

const feriadosRoutes = [
    { path: '/feriados', element: <ListaFeriados /> },
    { path: '/feriados/new', element: <FormularioFeriados /> },
    { path: '/feriados/:id', element: <FormularioFeriados /> },
];

export default feriadosRoutes;