import { useState, useEffect } from 'react';
import AplicativoContext from '../contexts/AplicativoContext';

const AplicativoContextProvider = ({ children }) => {
    const [ aplicativo, setAplicativo ] = useState({});

    return (
        <AplicativoContext.Provider value={{ aplicativo, setAplicativo }}>
            {children}
        </AplicativoContext.Provider>
    );
};

export default AplicativoContextProvider;