import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const Estabelecimentos = Loadable(lazy(() => import('./Estabelecimentos')));
const Estabelecimento = Loadable(lazy(() => import('./Estabelecimento')));

const estabelecimentosRoutes = [
  { path: '/estabelecimento/unidades', element: <Estabelecimentos /> },
  { path: '/estabelecimento/:id', element: <Estabelecimento /> },
  { path: '/estabelecimento', element: <Estabelecimento /> }
];

export default estabelecimentosRoutes;
