import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaLojas = Loadable(lazy(() => import('./ListaLojas')));
const FormularioLojas = Loadable(lazy(() => import('./FormularioLojas')));

const lojasRoutes = [
    { path: '/lojas', element: <ListaLojas /> },
    { path: '/lojas/new', element: <FormularioLojas /> },
    { path: '/lojas/:id', element: <FormularioLojas /> },
];

export default lojasRoutes;