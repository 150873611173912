import { useState } from "react";
import { createContext } from "react";

export const AlertContext = createContext()

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({ open: false, message: "", type: "success" })

    return (
        <AlertContext.Provider value={{ alert, setAlert }}>
            { children }
        </AlertContext.Provider>
    )
}