import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaPedidosDeFerias = Loadable(lazy(() => import('./ListaPedidosDeFerias')));
const FormularioPedidosDeFerias = Loadable(lazy(() => import('./FormularioPedidosDeFerias')));

const pedidosDeFeriasRoutes = [
    { path: '/pedidos-de-ferias', element: <ListaPedidosDeFerias /> },
    { path: '/pedidos-de-ferias/new', element: <FormularioPedidosDeFerias /> },
    { path: '/pedidos-de-ferias/:id', element: <FormularioPedidosDeFerias /> },
];

export default pedidosDeFeriasRoutes;