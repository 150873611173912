import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaBeneficios = Loadable(lazy(() => import('./ListaBeneficios')));
const FormularioBeneficios = Loadable(lazy(() => import('./FormularioBeneficios')));

const beneficiosRoutes = [
    { path: '/beneficios', element: <ListaBeneficios /> },
    { path: '/beneficios/new', element: <FormularioBeneficios /> },
    { path: '/beneficios/:id', element: <FormularioBeneficios /> },
];

export default beneficiosRoutes;