import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaDepartamentos = Loadable(lazy(() => import('./ListaDepartamentos')));
const FormularioDepartamentos = Loadable(lazy(() => import('./FormularioDepartamentos')));

const departamentosRoutes = [
    { path: '/departamentos', element: <ListaDepartamentos /> },
    { path: '/departamentos/new', element: <FormularioDepartamentos /> },
    { path: '/departamentos/:id', element: <FormularioDepartamentos /> },
];

export default departamentosRoutes;