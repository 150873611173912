import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaSetores = Loadable(lazy(() => import('./ListaSetores')));
const FormularioSetores = Loadable(lazy(() => import('./FormularioSetores')));

const setoresRoutes = [
    { path: '/setores', element: <ListaSetores /> },
    { path: '/setores/new', element: <FormularioSetores /> },
    { path: '/setores/:id', element: <FormularioSetores /> },
];

export default setoresRoutes;