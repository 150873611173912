import AuthGuard from 'app/auth/AuthGuard';
import Redirect from 'app/auth/Redirect';
import MatxLayout from 'app/components/MatxLayout/MatxLayout';
import Account from 'app/views/account';
import Home from './views/Home';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import pagesRoutes from 'app/views/pagesRoutes';
import clientesRoutes from 'app/views/clientes/ClientesRoutes';
import filasRoutes from 'app/views/filas/FilasRoutes';
import guichesRoutes from './views/guiches/GuichesRoutes';
import servicosRoutes from './views/servicos/ServicosRoutes';
import chamadosRoutes from 'app/views/chamados/ChamadosRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import usuariosRoutes from 'app/views/usuarios/usuariosRoutes';
import colaboradoresRoutes from './views/colaboradores/ColaboradoresRoutes';
import feriadosRoutes from './views/feriados/FeriadosRoutes';
import setoresRoutes from './views/setores/SetoresRoutes';
import lojasRoutes from './views/lojas/LojasRoutes';
import ausenciasRoutes from './views/ausencias/AusenciasRoutes';
import cargosRoutes from './views/cargos/CargosRoutes';
import departamentosRoutes from './views/departamentos/DepartamentosRoutes';
import horariosRoutes from './views/horarios/HorariosRoutes';
import estabelecimentosRoutes from './views/estabelecimento/EstabelecimentosRoutes';
import unidadesRoutes from './views/unidades/UnidadesRoutes';
import planosRoutes from './views/planos/PlanosRoutes';
import atendimentosRoutes from './views/atendimentos/AtendimentosRoutes';
import registrosDePontosRoutes from './views/registrosDePontos/RegistrosDePontosRoutes';
import pedidosDeFeriasRoutes from './views/pedidosDeFerias/pedidosDeFeriasRoutes';
import beneficiosRoutes from './views/beneficios/BeneficiosRoutes';
import pedidosDeLicencasRoutes from './views/pedidosDeLicencas/pedidosDeLicencasRoutes';
import ticketsAbonoRoutes from './views/ticket-abono/TicketsAbonoRoutes';
import tecnicosRoutes from './views/tecnicos/TecnicosRoutes';
import relatoriosRoutes from './views/relatorios/relatoriosRoutes';
import pedidosDeBancosDeHorasRoutes from './views/pedidosDeBancosDeHoras/pedidosDeBancosDeHorasRoutes';
import gerenciamentoDePontoRoutes from './views/gerenciamentoDePonto/gerenciamentoDePontoRoutes';
import categoriasLancamentosRoutes from './views/categoriasLancamentos/CategoriasLancamentosRoutes';
import lancamentosRoutes from './views/lancamentos/LancamentosRoutes';
import ifoodBeneficiosRoutes from './views/ifoodBeneficios/ifoodBeneficiosRoutes';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...pagesRoutes,
      ...estabelecimentosRoutes,
      ...unidadesRoutes,
      ...clientesRoutes,
      ...filasRoutes,
      ...guichesRoutes,
      ...servicosRoutes,
      ...atendimentosRoutes,
      ...chamadosRoutes,
      ...usuariosRoutes,
      ...colaboradoresRoutes,
      ...feriadosRoutes,
      ...setoresRoutes,
      ...lojasRoutes,
      ...ausenciasRoutes,
      ...cargosRoutes,
      ...departamentosRoutes,
      ...horariosRoutes,
      ...planosRoutes,
      ...registrosDePontosRoutes,
      ...pedidosDeFeriasRoutes,
      ...beneficiosRoutes,
      ...pedidosDeLicencasRoutes,
      ...ticketsAbonoRoutes,
      ...tecnicosRoutes,
      ...relatoriosRoutes,
      ...pedidosDeBancosDeHorasRoutes,
      ...gerenciamentoDePontoRoutes,
      ...categoriasLancamentosRoutes,
      ...lancamentosRoutes,
      ...ifoodBeneficiosRoutes,
      { path: '/page-layouts/account', element: <Account /> },
      { path: '/inicio', element: <Home /> },
    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Redirect /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
