import styled from '@emotion/styled';
import { Button, Card, CardContent, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Breadcrumb } from 'app/components'
import { AlertContext } from 'app/contexts/AlertContext';
import api from 'app/services/api';
import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import Webcam from 'react-webcam';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
}));

const RegistrosDePontos = () => {
    const webcamRef = useRef(null);
    const [colaborador, setColaborador] = useState({})
    const [horario, setHorario] = useState({})
    const [pontoHoje, setPontoHoje] = useState({})
    const [registrarDisabled, setRegistrarDisabled] = useState(false)

    const [loading, setLoading] = useState(false)
    const { setAlert } = useContext(AlertContext)

    const loadInformacoesIniciais = async () => {
        try {
            setLoading(true)
            let response = await api.get('/RegistrosDePontos/InformacoesIniciais')

            if (response.data.usuario?.colaborador === null) {
                throw new Error('Colaborador não encontrado para seu usuário. Entre em contato com o RH')
            }

            if (response.data.usuario?.colaborador?.horario === null) {
                throw new Error('Horário não encontrado para seu colaborador. Entre em contato com o RH')
            }

            setColaborador(response.data.usuario?.colaborador)
            setHorario(response.data.usuario?.colaborador?.horario)
            setPontoHoje(response.data.registroDePontoHoje)

        } catch (e) {
            setAlert({ open: true, message: e.response?.data?.message ?? 'Erro ao carregar informações iniciais', type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        loadInformacoesIniciais()
    }, [])

    const handleRegistrarPonto = async () => {
        try {
            setLoading(true)

            let imageSrc = webcamRef.current.getScreenshot()
            await api.post('/RegistrosDePontos', { foto: imageSrc })

            setAlert({ open: true, message: 'Ponto Registrado Com Sucesso', type: 'success' })
            loadInformacoesIniciais()
        } catch (e) {
            setAlert({ open: true, message: e.response?.data?.message ?? 'Erro ao registrar ponto', type: 'error' })
        } finally {
            setLoading(false)
        }
    }


    return (
        <Container>
            <Breadcrumb
                routeSegments={[
                    { name: 'Registro de Ponto' },
                ]}
            />

            <Card
                sx={{
                    mt: 2,
                    p: 2
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Registro de Ponto
                </Typography>
                <Divider />

                <CardContent sx={{ px: 0 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} md={6}>
                            <Webcam
                                audio={false}
                                mirrored={true}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={6}>
                            <h3>Colaborador</h3>
                            <h2>{ colaborador?.nome ?? '-' }</h2>
                            <Divider />
                            <h3>Horário</h3>
                            <h2>{ horario?.descricao ?? '-' }</h2>
                            <Divider />
                            <h3>Seu Ponto Hoje { dayjs().format('DD/MM/YYYY') }</h3>
                            <TableContainer component={Paper} sx={{ px: 2 }}>
                                <Table sx={{ width: '100%' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Entrada 1</TableCell>
                                            <TableCell>Saída 1</TableCell>
                                            <TableCell>Entrada 2</TableCell>
                                            <TableCell>Saída 2</TableCell>
                                            <TableCell>Entrada 3</TableCell>
                                            <TableCell>Saída 3</TableCell>
                                            <TableCell>Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{ pontoHoje?.entrada1 ? dayjs(pontoHoje?.entrada1).format('HH:mm') : '-'}</TableCell>
                                            <TableCell>{ pontoHoje?.saida1 ? dayjs(pontoHoje?.saida1).format('HH:mm') : '-'}</TableCell>
                                            <TableCell>{ pontoHoje?.entrada2 ? dayjs(pontoHoje?.entrada2).format('HH:mm') : '-'}</TableCell>
                                            <TableCell>{ pontoHoje?.saida2 ? dayjs(pontoHoje?.saida2).format('HH:mm') : '-'}</TableCell>
                                            <TableCell>{ pontoHoje?.entrada3 ? dayjs(pontoHoje?.entrada3).format('HH:mm') : '-'}</TableCell>
                                            <TableCell>{ pontoHoje?.saida3 ? dayjs(pontoHoje?.saida3).format('HH:mm') : '-'}</TableCell>
                                            <TableCell>{ pontoHoje?.duracao ? pontoHoje?.duracao : '-'}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Divider />
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{ mt: 5 }}
                                onClick={handleRegistrarPonto}
                                disabled={loading || registrarDisabled}
                            >REGISTRAR PONTO AGORA</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    )
}

export default RegistrosDePontos