import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaAusencias = Loadable(lazy(() => import('./ListaAusencias')));
const FormularioAusencias = Loadable(lazy(() => import('./FormularioAusencias')));

const ausenciasRoutes = [
    { path: '/ausencias', element: <ListaAusencias /> },
    { path: '/ausencias/new', element: <FormularioAusencias /> },
    { path: '/ausencias/:id', element: <FormularioAusencias /> },
];

export default ausenciasRoutes;