import React from 'react';
import { Paper, Button  } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { Container } from 'app/styles';


const Home = () => {
    return (
        <Container>
            {/* <Carousel>
                {
                    items.map((item, i) => <Item key={i} item={item} /> )
                }
            </Carousel> */}
        </Container>
    );
}

export default Home;