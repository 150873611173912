import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaColaboradores = Loadable(lazy(() => import('./ListaColaboradores')));
const FormularioColaboradores = Loadable(lazy(() => import('./FormularioColaboradores')));

const colaboradoresRoutes = [
    { path: '/colaboradores', element: <ListaColaboradores /> },
    { path: '/colaboradores/new', element: <FormularioColaboradores /> },
    { path: '/colaboradores/:id', element: <FormularioColaboradores /> },
];

export default colaboradoresRoutes;