import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Divider, Grid, Stack, styled, TextField } from "@mui/material";
import { FlexBox } from "app/components/FlexBox";
import { H5, Paragraph } from "app/components/Typography";
import useAuth from "app/hooks/useAuth";
import api from "app/services/api";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import * as Yup from "yup";

const Dot = styled(Box)(({ theme }) => ({
    width: 8,
    height: 8,
    flexShrink: 0,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
}));

const Password = () => {
    const { logout, user } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [ loading, setLoading ] = useState(false);

    const initialValues = {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    };

    const validationSchema = Yup.object({
        currentPassword: Yup.string()
                            .min(6, 'Deve ter mais de 6 caracteres')
                            .required('A senha atual é obrigatória!'),
        newPassword: Yup.string()
                        .min(8).required('Nova senha é obrigatória!'),
        confirmNewPassword: Yup.string().oneOf(
            [Yup.ref("newPassword"), null],
            'A nova senha não corresponde'
        ),
    });

    const { values, errors, handleSubmit, handleChange, handleBlur, touched } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            // console.log(values)
            defineSenha(values);
        },
    });

    const defineSenha = async (values) => {
        setLoading(true);
        const body = {
            senhaAtual: values.currentPassword,
            novaSenha: values.newPassword
        }
        await api.put(`/Usuario/${user.id}/TrocaSenha`, body)
            .then((response) => {
                enqueueSnackbar('Senha alterada com sucesso.', { variant: 'success' });
                logout();
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Card>
            <H5 padding={3}>Senha</H5>
            <Divider />

            <Box padding={3}>
                <Grid container spacing={5}>
                <Grid item sm={6} xs={12}>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={4}>
                            <TextField
                                fullWidth
                                type="password"
                                variant="outlined"
                                name="currentPassword"
                                label="Senha atual"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.currentPassword}
                                helperText={touched.currentPassword && errors.currentPassword}
                                error={Boolean(touched.currentPassword && errors.currentPassword)}
                            />

                            <TextField
                                fullWidth
                                type="password"
                                name="newPassword"
                                variant="outlined"
                                label="Nova senha"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.newPassword}
                                helperText={touched.newPassword && errors.newPassword}
                                error={Boolean(touched.newPassword && errors.newPassword)}
                            />
                            <TextField
                                fullWidth
                                type="password"
                                variant="outlined"
                                name="confirmNewPassword"
                                label="Confirmação"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.confirmNewPassword}
                                helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                                error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
                            />
                        </Stack>

                        <Stack direction="row" spacing={3} mt={4}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={loading}
                            >
                                Gravar
                            </LoadingButton>
                            <LoadingButton
                                variant="outlined"
                                loading={loading}
                            >
                                Cancelar
                            </LoadingButton>
                        </Stack>
                    </form>
                </Grid>

                <Grid item sm={6} xs={12}>
                    <H5>Requisitos de senha:</H5>
                    <Paragraph lineHeight={1.7}>Certifique-se de que esses requisitos sejam atendidos:</Paragraph>

                    <Stack spacing={1} mt={2}>
                    <FlexBox alignItems="center" gap={1}>
                        <Dot />
                        <Paragraph fontSize={13}>
                            Mínimo de 8 caracteres - quanto mais, melhor
                        </Paragraph>
                    </FlexBox>

                    <FlexBox alignItems="center" gap={1}>
                        <Dot />
                        <Paragraph fontSize={13}>
                            Pelo menos um caractere minúsculo
                        </Paragraph>
                    </FlexBox>

                    <FlexBox alignItems="center" gap={1}>
                        <Dot />
                        <Paragraph fontSize={13}>
                            Pelo menos um caractere maiúsculo
                        </Paragraph>
                    </FlexBox>

                    <FlexBox alignItems="center" gap={1}>
                        <Dot />
                        <Paragraph fontSize={13}>
                            Pelo menos um número, símbolo ou caractere de espaço em branco
                        </Paragraph>
                    </FlexBox>
                    </Stack>
                </Grid>
                </Grid>
            </Box>
        </Card>
    );
};

export default Password;
