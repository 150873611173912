import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const Unidades = Loadable(lazy(() => import('./Unidades')));
const Unidade = Loadable(lazy(() => import('./Unidade')));

const unidadesRoutes = [
  { path: '/unidades', element: <Unidades /> },
  { path: '/unidade', element: <Unidade /> },
  { path: '/estabelecimento/:estabelecimentoId/unidade/:id', element: <Unidade /> }
];

export default unidadesRoutes;
