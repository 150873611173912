import { lazy } from 'react';
import Loadable from 'app/components/Loadable';
const Usuarios = Loadable(lazy(() => import('./Usuarios')));
const Usuario = Loadable(lazy(() => import('./Usuario')));

const usuariosRoutes = [
    { path: '/usuarios', element: <Usuarios /> },
    { path: '/usuarios/usuario', element: <Usuario /> },
    { path: '/usuarios/usuario/:id', element: <Usuario /> },
];

export default usuariosRoutes;
