import Mock from "../mock";

const clientDB = {
    clients: [
        {
          "cliCodigo": 109,
          "cliCep": "69099006",
          "cliFone1": "5592991471968",
          "cliFone2": "5592993190344",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "jefersonthiago@outlook.com",
          "cliNome": "JEFERSON THIAGO BASILIO DE LIMA",
          "cliFantasia": "JEFERSON THIAGO BASILIO DE LIMA",
          "cliEndereco": "R Êxodo",
          "cliNumero": "68",
          "cliBairro": "Novo Aleixo",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "005.414.812-07",
          "cliComplemento": "NOSSA SENHORA FATIMA 1",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 116,
          "cliCep": "",
          "cliFone1": "5592992807162",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "greice_islane@hotmail.com",
          "cliNome": "GREICE ISLANE OLIVEIRA DA SILVA",
          "cliFantasia": "GREICE ISLANE OLIVEIRA DA SILVA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "821.391.482-15",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 117,
          "cliCep": "",
          "cliFone1": "5592984222147",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "dariosilva859@gmail.com",
          "cliNome": "DARIO OLIVEIRA DA SILVA",
          "cliFantasia": "DARIO OLIVEIRA DA SILVA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "818.530.332-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 151,
          "cliCep": "69085001",
          "cliFone1": "92993746087",
          "cliFone2": "92993309495",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "silva2304reginaldo@gmail.com",
          "cliNome": "REGINALDO PEREIRA DA SILVA",
          "cliFantasia": "REGINALDO PEREIRA DA SILVA",
          "cliEndereco": "R Politéia",
          "cliNumero": "95",
          "cliBairro": "São José Operário",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "743.899.552-68",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 166,
          "cliCep": "69415000",
          "cliFone1": "92992447666",
          "cliFone2": "92991939626",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "eudes.edsds@gmail.com",
          "cliNome": "EUDES DA SILVA DOS SANTOS",
          "cliFantasia": "EUDES DA SILVA DOS SANTOS",
          "cliEndereco": "Iranduba",
          "cliNumero": "320",
          "cliBairro": "Cidade Nova",
          "cliCidade": "Iranduba",
          "cliEstado": "AM",
          "cliCnpjCpf": "814.603.762-34",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 206,
          "cliCep": "69027110",
          "cliFone1": "92995059461",
          "cliFone2": "92992734260",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "mara_pires21@hotmail.com",
          "cliNome": "WILSON JORGE NEVES DINIZ",
          "cliFantasia": "WILSON JORGE NEVES DINIZ",
          "cliEndereco": "Av Pres Dutra",
          "cliNumero": "437",
          "cliBairro": "São Raimundo",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "658.712.272-87",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 235,
          "cliCep": "69099217",
          "cliFone1": "92994192912",
          "cliFone2": "92994505946",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "994192912bruno@gmail.com",
          "cliNome": "BRUNO PARENTE LIMA",
          "cliFantasia": "BRUNO PARENTE LIMA",
          "cliEndereco": "R Esmeralda",
          "cliNumero": "100",
          "cliBairro": "Cidade de Deus",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "019.581.742-70",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 241,
          "cliCep": "69088137",
          "cliFone1": "92992109680",
          "cliFone2": "92994339510",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "mateusguimaraes0080@gmail.com",
          "cliNome": "MATEUS GUIMARÃES AZEVEDO",
          "cliFantasia": "MATEUS GUIMARÃES AZEVEDO",
          "cliEndereco": "R Luís Montenegro",
          "cliNumero": "63",
          "cliBairro": "Jorge Teixeira",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "001.609.022-58",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 257,
          "cliCep": "69090327",
          "cliFone1": "92995191886",
          "cliFone2": "9236455015",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "sebastiana.lopes.de.oliveira@gmail.com",
          "cliNome": "SEBASTIANA LOPES DE OLIVEIRA",
          "cliFantasia": "SEBASTIANA LOPES DE OLIVEIRA",
          "cliEndereco": "R Itaitê",
          "cliNumero": "1449",
          "cliBairro": "Cidade Nova",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "077.336.402-10",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 277,
          "cliCep": "69095720",
          "cliFone1": "92994013470",
          "cliFone2": "92984001689",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "eucarllynhoss22@gmail.com",
          "cliNome": "CARLOS ALEXANDRE DOS SANTOS MOREIRA",
          "cliFantasia": "CARLOS ALEXANDRE DOS SANTOS MOREIRA",
          "cliEndereco": "R Antônia Carneiro",
          "cliNumero": "08",
          "cliBairro": "Nova Cidade",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "766.751.492-53",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": "FERNANDA.LOPES 05/07/2019 17:35 - SEM INFORMAÇÕES NO ASAAS - CLIENTE DEVENDO OS MESES DE MARÇO E MAIO DE 2018 - SEM QUEBRA DE CONTRATO - INCLUSO NO SISTEMA CDL"
        },
        {
          "cliCodigo": 309,
          "cliCep": "69084390",
          "cliFone1": "92992787741",
          "cliFone2": "92984404724",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "biiell.maiia@gmail.com",
          "cliNome": "GABRIEL NOGUEIRA MAIA",
          "cliFantasia": "GABRIEL NOGUEIRA MAIA",
          "cliEndereco": "R João B Burnier",
          "cliNumero": "143",
          "cliBairro": "Zumbi dos Palmares",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "025.924.332-95",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 323,
          "cliCep": "69018150",
          "cliFone1": "92991690287",
          "cliFone2": "92995310755",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "mlima1504@gmail.com",
          "cliNome": "MARIA JOSE LIMA DA SILVA",
          "cliFantasia": "MARIA JOSE LIMA DA SILVA",
          "cliEndereco": "Av Cdor José Cruz",
          "cliNumero": "515",
          "cliBairro": "Lago Azul",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "793.895.462-49",
          "cliComplemento": "BLOCO 06 APARTAMENTO 101 CONDOMINIO LIBERDADE",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 358,
          "cliCep": "69015764",
          "cliFone1": "92981038189",
          "cliFone2": "92993458107",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "a981038189@gmail.com",
          "cliNome": "WELLINGTON SERRAO DE OLIVEIRA",
          "cliFantasia": "WELLINGTON SERRAO DE OLIVEIRA",
          "cliEndereco": "RUA 7",
          "cliNumero": "403",
          "cliBairro": "Colônia Terra Nova",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "010.909.492-19",
          "cliComplemento": "BLOCO 14",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 370,
          "cliCep": "69035366",
          "cliFone1": "92991869570",
          "cliFone2": "92994303997",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "lg427995@gmail.com",
          "cliNome": "CHARLES GOMES FREITAS",
          "cliFantasia": "CHARLES GOMES FREITAS",
          "cliEndereco": "R T 6",
          "cliNumero": "51",
          "cliBairro": "Compensa",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "010.074.022-70",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 375,
          "cliCep": "69035180",
          "cliFone1": "92994950115",
          "cliFone2": "92995059297",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "thiagosilvats1234567@gmail.com",
          "cliNome": "THIAGO DA SILVA E SILVA",
          "cliFantasia": "THIAGO DA SILVA E SILVA",
          "cliEndereco": "R Castelo Branco",
          "cliNumero": "74",
          "cliBairro": "Compensa",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "820.957.522-87",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 394,
          "cliCep": "69027250",
          "cliFone1": "92991969435",
          "cliFone2": "92992926180",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "flamengoracajovem87@gmail.com",
          "cliNome": "MARCELO ALVES DA SILVA",
          "cliFantasia": "MARCELO ALVES DA SILVA",
          "cliEndereco": "R Boa Vista",
          "cliNumero": "157",
          "cliBairro": "São Raimundo",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "568.490.282-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 412,
          "cliCep": "69090746",
          "cliFone1": "92981273112",
          "cliFone2": "92984348151",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "souza.fabio20@outlook.com",
          "cliNome": "FABIO SOUZA DE OLIVEIRA JUNIOR",
          "cliFantasia": "FABIO SOUZA DE OLIVEIRA JUNIOR",
          "cliEndereco": "R Elton Mayo",
          "cliNumero": "44",
          "cliBairro": "Cidade Nova",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "171.249.497-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 457,
          "cliCep": "69075-32",
          "cliFone1": "92993849264",
          "cliFone2": "92994840982",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "jflima7528@gmail.com",
          "cliNome": "FRANCISCO JOSE FERNANDES DE LIMA",
          "cliFantasia": "FRANCISCO JOSE FERNANDES DE LIMA",
          "cliEndereco": "13",
          "cliNumero": "39",
          "cliBairro": "Mauazinho",
          "cliCidade": "MANAUS",
          "cliEstado": "AM",
          "cliCnpjCpf": "693.416.802-04",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 509,
          "cliCep": "69099414",
          "cliFone1": "11947985156",
          "cliFone2": "11953884475",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "diannerafaelle@hotmail.com",
          "cliNome": "DIANNE RAFAELLE TRINDADE CAVALCANTI",
          "cliFantasia": "DIANNE RAFAELLE TRINDADE CAVALCANTI",
          "cliEndereco": "R Colhereira",
          "cliNumero": "03",
          "cliBairro": "Cidade de Deus",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "828.424.502-72",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 514,
          "cliCep": "69047080",
          "cliFone1": "92995015068",
          "cliFone2": "92991648393",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "alexandrerosa12345678910@gmail.com",
          "cliNome": "NEILA GOMES DA SILVA",
          "cliFantasia": "NEILA GOMES DA SILVA",
          "cliEndereco": "R Bela Vista",
          "cliNumero": "41",
          "cliBairro": "Redenção",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "931.373.462-15",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 550,
          "cliCep": "69099227",
          "cliFone1": "92992446479",
          "cliFone2": "92993560303",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "jamesonrocha1@gmail.com",
          "cliNome": "JAMESON SOUZA ROCHA",
          "cliFantasia": "JAMESON SOUZA ROCHA",
          "cliEndereco": "R N Sra da Conceição",
          "cliNumero": "178",
          "cliBairro": "Cidade de Deus",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "905.301.102-10",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 598,
          "cliCep": "",
          "cliFone1": "92994144489",
          "cliFone2": "92993098925",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "elvinsleno@gmail.com",
          "cliNome": "ELVINS LENO OLIVEIRA SALES",
          "cliFantasia": "ELVINS LENO OLIVEIRA SALES",
          "cliEndereco": "",
          "cliNumero": "203",
          "cliBairro": "SANTA ETELVINA",
          "cliCidade": "MANAUS",
          "cliEstado": "AM",
          "cliCnpjCpf": "013.460.862-30",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 715,
          "cliCep": "",
          "cliFone1": "92999449140",
          "cliFone2": "92992273349",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "vendedormagistral219@gmail.com",
          "cliNome": "JOAB CORESMA LEMOS",
          "cliFantasia": "JOAB CORESMA LEMOS",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "013.233.352-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 720,
          "cliCep": "69043007",
          "cliFone1": "92992396151",
          "cliFone2": "92992299585",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "karolinediaspereira@gmail.com",
          "cliNome": "MIKAEL ALVES FREITAS",
          "cliFantasia": "MIKAEL ALVES FREITAS",
          "cliEndereco": "R F",
          "cliNumero": "14",
          "cliBairro": "Alvorada",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "007.236.172-79",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 746,
          "cliCep": "69049070",
          "cliFone1": "92981271778",
          "cliFone2": "92981311186",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "cllmanutecaoindustrial@gmail.com",
          "cliNome": "CLEBER DA SILVA PINHEIRO",
          "cliFantasia": "CLEBER DA SILVA PINHEIRO",
          "cliEndereco": "R Cmte Henrique Bastos",
          "cliNumero": "5553",
          "cliBairro": "Paz",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "000.738.186-74",
          "cliComplemento": "Bloco B Apt. 204",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 773,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "teste SystemSat",
          "cliFantasia": "teste SystemSat",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "000.000.000-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 803,
          "cliCep": "69038640",
          "cliFone1": "92994697566",
          "cliFone2": "92991678864",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "lendel378@gmail.com",
          "cliNome": "KELVIN LENDEL DE SOUZA COSTA",
          "cliFantasia": "KELVIN LENDEL DE SOUZA COSTA",
          "cliEndereco": "R Tapiramuta",
          "cliNumero": "36",
          "cliBairro": "Lírio do Vale",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "016.825.102-71",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 841,
          "cliCep": "",
          "cliFone1": "92994690835",
          "cliFone2": "92991387629",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "nandoejoyce6@gmail.com",
          "cliNome": "FERNANDO OLIVEIRA DA SILVA NETO",
          "cliFantasia": "FERNANDO OLIVEIRA DA SILVA NETO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "549.868.182-87",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 858,
          "cliCep": "",
          "cliFone1": "92993201668",
          "cliFone2": "92994990895",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "thainasilvahelo@gmail.com",
          "cliNome": "JULIO CESAR NOGUEIRA MARINHO",
          "cliFantasia": "JULIO CESAR NOGUEIRA MARINHO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "028.142.792-54",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 889,
          "cliCep": "69087655",
          "cliFone1": "92994051878",
          "cliFone2": "92981811464",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "will.wd74@gmail.com",
          "cliNome": "WILLIAM DAVID BATISTA GOMES",
          "cliFantasia": "WILLIAM DAVID BATISTA GOMES",
          "cliEndereco": "R Rio Jaquirana",
          "cliNumero": "106",
          "cliBairro": "Tancredo Neves",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "003.565.182-21",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 934,
          "cliCep": "69093315",
          "cliFone1": "92991073124",
          "cliFone2": "92992662777",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "ervellynkatri@yahoo.com.br",
          "cliNome": "ERVELLYN KATRI ALVES LEITAO",
          "cliFantasia": "ERVELLYN KATRI ALVES LEITAO",
          "cliEndereco": "Tv S José",
          "cliNumero": "71",
          "cliBairro": "Colônia Santo Antônio",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "025.315.132-56",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 945,
          "cliCep": "",
          "cliFone1": "991462371",
          "cliFone2": "992662368",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "CLEINILDO SILVA DE SOUZA",
          "cliFantasia": "CLEINILDO SILVA DE SOUZA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "752.384.692-91",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 946,
          "cliCep": "",
          "cliFone1": "993203304",
          "cliFone2": "992453475",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "MARILEIA RODRIGUES DE SOUZA",
          "cliFantasia": "MARILEIA RODRIGUES DE SOUZA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "797.584.362-87",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 947,
          "cliCep": "69043020",
          "cliFone1": "994876236",
          "cliFone2": "992179815",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "alone_morais@hotmail.com",
          "cliNome": "NERIMAR MURAIARE",
          "cliFantasia": "NERIMAR MURAIARE",
          "cliEndereco": "R Independência",
          "cliNumero": "19",
          "cliBairro": "Alvorada",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "320.095.772-72",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 952,
          "cliCep": "",
          "cliFone1": "991219040",
          "cliFone2": "981128836",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "VAGNER DOS SANTOS SILVA",
          "cliFantasia": "VAGNER DOS SANTOS SILVA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "807.025.032-15",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 957,
          "cliCep": "",
          "cliFone1": "991658965",
          "cliFone2": "36512743",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "JOICE C. DE ANDRADE RIBEIRO",
          "cliFantasia": "CRH TRANSPORTE & LOGÍSTICA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "26.009.390/0001-54",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 959,
          "cliCep": "",
          "cliFone1": "992951718",
          "cliFone2": "994359393",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "KELSON VINICIUS CORREA SOARES",
          "cliFantasia": "KELSON VINICIUS CORREA SOARES",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "037.146.313-05",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 966,
          "cliCep": "",
          "cliFone1": "981889799",
          "cliFone2": "33089806",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "JOSEILTON GUARINO DA ROCHA",
          "cliFantasia": "JOSEILTON GUARINO DA ROCHA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "295.875.918-01",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 970,
          "cliCep": "",
          "cliFone1": "993266580",
          "cliFone2": "993361347",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "NILTON CESAR SILVA BASTOS",
          "cliFantasia": "NILTON CESAR SILVA BASTOS",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "005.321.452-83",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 971,
          "cliCep": "",
          "cliFone1": "992701732",
          "cliFone2": "991328063",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "IZAQUIEL DE SILVA SOUZA",
          "cliFantasia": "IZAQUIEL DE SILVA SOUZA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "020.238.262-17",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 974,
          "cliCep": "",
          "cliFone1": "994257855",
          "cliFone2": "991295334",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "GEANDRE RIBEIRO DOS SANTOS",
          "cliFantasia": "GEANDRE RIBEIRO DOS SANTOS",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "804.102.432-72",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 981,
          "cliCep": "",
          "cliFone1": "992508349",
          "cliFone2": "992592933",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "ARINELSON DA SILVA",
          "cliFantasia": "ARINELSON DA SILVA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "519.819.632-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 982,
          "cliCep": "",
          "cliFone1": "991834163",
          "cliFone2": "991422583",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "JORGE ALESSANDRO DA SILVA REIS",
          "cliFantasia": "JORGE ALESSANDRO DA SILVA REIS",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "592.807.862-53",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 985,
          "cliCep": "",
          "cliFone1": "982335232",
          "cliFone2": "992909875",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "JOAO BOSCO DE ARAÚJO FIGUEIREDO",
          "cliFantasia": "JOAO BOSCO DE ARAÚJO FIGUEIREDO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "533.887.712-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 994,
          "cliCep": "",
          "cliFone1": "981091015",
          "cliFone2": "36340218",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "RODRIGO ARAÚJO DANTAS",
          "cliFantasia": "RODRIGO ARAÚJO DANTAS",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "527.918.072-68",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 995,
          "cliCep": "",
          "cliFone1": "992439611",
          "cliFone2": "992723002",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "EDERSON DOS SANTOS FIGARELA",
          "cliFantasia": "EDERSON DOS SANTOS FIGARELA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "004.337.452-23",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1003,
          "cliCep": "",
          "cliFone1": "994949663",
          "cliFone2": "991035575",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "MATEUS VICENTE MONTEIRO",
          "cliFantasia": "MATEUS VICENTE MONTEIRO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "931.606.822-34",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1004,
          "cliCep": "",
          "cliFone1": "992269537",
          "cliFone2": "995015214",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "RAFAEL BRUNO DE AGUIAR",
          "cliFantasia": "RAFAEL BRUNO DE AGUIAR",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "939.821.862-34",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1008,
          "cliCep": "",
          "cliFone1": "991203154",
          "cliFone2": "991540783",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "RONNEBERG COSTA DA SILVA",
          "cliFantasia": "RONNEBERG COSTA DA SILVA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "836.581.652-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1018,
          "cliCep": "",
          "cliFone1": "994344646",
          "cliFone2": "994985604",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "MIZAEL AGUIAR DA SILVA",
          "cliFantasia": "MIZAEL AGUIAR DA SILVA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "447.278.442-49",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1019,
          "cliCep": "",
          "cliFone1": "991299897",
          "cliFone2": "992313954",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "CARLOS AUGUSTO DE SOUZA LIRA",
          "cliFantasia": "CARLOS AUGUSTO DE SOUZA LIRA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "002.768.702-39",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1021,
          "cliCep": "",
          "cliFone1": "991359135",
          "cliFone2": "981006256",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "EDIMARA DE SOUZA TEIXEIRA",
          "cliFantasia": "EDIMARA DE SOUZA TEIXEIRA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "494.124.342-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1023,
          "cliCep": "",
          "cliFone1": "991801758",
          "cliFone2": "999654828",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "WESLEY OLIVEIRA COLLYER SOBRINHO",
          "cliFantasia": "WESLEY OLIVEIRA COLLYER SOBRINHO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "009.818.942-59",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1026,
          "cliCep": "",
          "cliFone1": "994017120",
          "cliFone2": "982893661",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "MESSIAS BUTEL DOS SANTOS",
          "cliFantasia": "MESSIAS BUTEL DOS SANTOS",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "013.885.482-32",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1035,
          "cliCep": "",
          "cliFone1": "991985097",
          "cliFone2": "994986930",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "AURIVAN BEZERRA DA SILVA",
          "cliFantasia": "AURIVAN BEZERRA DA SILVA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "309.622.502-87",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1039,
          "cliCep": "",
          "cliFone1": "991202814",
          "cliFone2": "992125118",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "CLAUDIO SILVA E SOUZA",
          "cliFantasia": "CLAUDIO SILVA E SOUZA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "445.630.272-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1044,
          "cliCep": "",
          "cliFone1": "992885707",
          "cliFone2": "995274728",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "MARCELO DANTAS DA COSTA",
          "cliFantasia": "MARCELO DANTAS DA COSTA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "584.294.842-72",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1053,
          "cliCep": "69084120",
          "cliFone1": "92982225730",
          "cliFone2": "92992473240",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "geysila8@gmail.com",
          "cliNome": "GEYSILA CARLA DA SILVA RAMOS",
          "cliFantasia": "GEYSILA CARLA DA SILVA RAMOS",
          "cliEndereco": "R Dr Basílio",
          "cliNumero": "2C",
          "cliBairro": "Zumbi dos Palmares",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "018.198.862-39",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1060,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "YUKIO LEE ABE",
          "cliFantasia": "YUKIO LEE ABE",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "053.686.367-92",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1061,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "MARICELHO ALVES BIDO",
          "cliFantasia": "MARICELHO ALVES BIDO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "065.283.974-65",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1062,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "LENILSON OLIVEIRA",
          "cliFantasia": "LENILSON OLIVEIRA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "940.155.892-20",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1063,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "CLEUTON GABRIEL SERRÃO",
          "cliFantasia": "CLEUTON GABRIEL SERRÃO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "002.546.542-23",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1064,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "ANTONIO BARBOSA JUNIOR",
          "cliFantasia": "ANTONIO BARBOSA JUNIOR",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "704.344.682-15",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1065,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "JULIO ASCANIO SANTOS CARDOSO",
          "cliFantasia": "JULIO ASCANIO SANTOS CARDOSO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "335.878.092-91",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1067,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "FRANCISCO CELIO DO AMARAL SALES",
          "cliFantasia": "FRANCISCO CELIO DO AMARAL SALES",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "238.261.262-20",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1069,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "ANDERSON SANTOS LIMA",
          "cliFantasia": "ANDERSON SANTOS LIMA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "688.954.292-49",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1071,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "TAYLA ARAUJO LEITE",
          "cliFantasia": "TAYLA ARAUJO LEITE",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "006.003.702-40",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1072,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "ROBSON DE SOUZA CARVALHO",
          "cliFantasia": "ROBSON DE SOUZA CARVALHO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "871.815.762-91",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1074,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "BARBARA CAROLINA SATURNINO",
          "cliFantasia": "BARBARA CAROLINA SATURNINO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "936.807.322-87",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1075,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "ROMULO FREIRE DE LIMA",
          "cliFantasia": "ROMULO FREIRE DE LIMA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "877.721.912-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1079,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "JONILSON DA COSTA MORAES",
          "cliFantasia": "JONILSON DA COSTA MORAES",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "004.722.682-01",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1081,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "ELSON EDUARDO SILVA CAMURÇA",
          "cliFantasia": "ELSON EDUARDO SILVA CAMURÇA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "008.295.142-01",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1083,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "DANIEL SIQUEIRA CARDOSO",
          "cliFantasia": "DANIEL SIQUEIRA CARDOSO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "882.183.922-20",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1085,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "CARLOS EDUARDO CONDE DA SILVA",
          "cliFantasia": "CARLOS EDUARDO CONDE DA SILVA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "011.069.542-97",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1086,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "GEORGE DE LIMA BARBOSA",
          "cliFantasia": "GEORGE DE LIMA BARBOSA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "939.688.092-20",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1087,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "JOSE FRANCISCO DE ESPINDOLA",
          "cliFantasia": "JOSE FRANCISCO DE ESPINDOLA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "663.641.402-20",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1088,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "ADECY BASTOS RIOS",
          "cliFantasia": "ADECY BASTOS RIOS",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "111.869.842-87",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1093,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "EDUARDO DE OLIVEIRA MELO",
          "cliFantasia": "EDUARDO DE OLIVEIRA MELO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "526.166.062-91",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1098,
          "cliCep": "",
          "cliFone1": "984460980",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "ANDERSON MATOS PEREIRA",
          "cliFantasia": "ANDERSON MATOS PEREIRA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "004.181.983-73",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1099,
          "cliCep": "",
          "cliFone1": "994595626",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "ANTONIO FRANCISCO LIMA DE ARAUJO",
          "cliFantasia": "ANTONIO FRANCISCO LIMA DE ARAUJO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "573.910.762-87",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1101,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "RONALDO GOMES CAMPOS",
          "cliFantasia": "RONALDO GOMES CAMPOS",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "025.861.512-59",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1103,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "JULIA RENATA GARCIA RAFAEL",
          "cliFantasia": "JULIA RENATA GARCIA RAFAEL",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "013.578.932-05",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1106,
          "cliCep": "",
          "cliFone1": "992627211",
          "cliFone2": "992363073",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "THIAGO PEDROSO MELO",
          "cliFantasia": "THIAGO PEDROSO MELO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "884.241.892-72",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1113,
          "cliCep": "",
          "cliFone1": "992800641",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "RAMAM BARBOSA FONSECA",
          "cliFantasia": "RAMAM BARBOSA FONSECA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "944.823.572-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1115,
          "cliCep": "",
          "cliFone1": "92992399167",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "JAIRO SIMOES DA SILVA",
          "cliFantasia": "JAIRO SIMOES DA SILVA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "836.774.702-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1118,
          "cliCep": "",
          "cliFone1": "982332500",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "MARCEL SANTOS BATISTA",
          "cliFantasia": "MARCEL SANTOS BATISTA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "721.312.172-34",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1121,
          "cliCep": "",
          "cliFone1": "994756047",
          "cliFone2": "992201313",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "RAFAEL SILVA DE AQUINO",
          "cliFantasia": "RAFAEL SILVA DE AQUINO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "840.716.212-49",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1122,
          "cliCep": "",
          "cliFone1": "981234439",
          "cliFone2": "991602060",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "EVANDRO GOMES BINDA JUNIOR",
          "cliFantasia": "EVANDRO GOMES BINDA JUNIOR",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "991.684.722-34",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1125,
          "cliCep": "",
          "cliFone1": "993253872",
          "cliFone2": "993354778",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "BENTO JUNIOR PINHEIRO RODRIGUES",
          "cliFantasia": "BENTO JUNIOR PINHEIRO RODRIGUES",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "963.272.002-49",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1127,
          "cliCep": "",
          "cliFone1": "994880301",
          "cliFone2": "992947088",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "FRANCISCO DOUGLAS SOUZA ALVEZ",
          "cliFantasia": "FRANCISCO DOUGLAS SOUZA ALVEZ",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "310.279.532-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1128,
          "cliCep": "",
          "cliFone1": "993281462",
          "cliFone2": "995135881",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "ANA JANDIRA DA SILVA SANTANA",
          "cliFantasia": "ANA JANDIRA DA SILVA SANTANA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "913.499.072-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1129,
          "cliCep": "",
          "cliFone1": "993142912",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "KLEITON CUNHA DE OLIVEIRA",
          "cliFantasia": "KLEITON CUNHA DE OLIVEIRA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "719.013.962-15",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1130,
          "cliCep": "",
          "cliFone1": "992195278",
          "cliFone2": "992195244",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "ITANO PEREIRA MOREIRA",
          "cliFantasia": "ITANO PEREIRA MOREIRA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "844.765.592-04",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": "DAIANE.ARAUJO 06/11/2020 10:27 - CLIENTE NÃO SE ENCONTRAVA NO SPC/SERASA."
        },
        {
          "cliCodigo": 1131,
          "cliCep": "",
          "cliFone1": "994979340",
          "cliFone2": "992535712",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "CLEUTON OLIVEIRA LOPES",
          "cliFantasia": "CLEUTON OLIVEIRA LOPES",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "012.439.572-47",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": "FERNANDA.LOPES 08/07/2019 12:39 - INFORMAÇÃO ASAAS- APARELHO RETIRADO DIA 27/04/2018 NÃO INCLUSO NO SPC"
        },
        {
          "cliCodigo": 1132,
          "cliCep": "",
          "cliFone1": "993711395",
          "cliFone2": "993895487",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "MAURO DE ALMEIDA NEGRÃO",
          "cliFantasia": "MAURO DE ALMEIDA NEGRÃO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "272.970.202-49",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1135,
          "cliCep": "",
          "cliFone1": "993649116",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "EDIVANEI NOGUEIRA DOS SANTOS",
          "cliFantasia": "EDIVANEI NOGUEIRA DOS SANTOS",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "025.969.262-80",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1140,
          "cliCep": "",
          "cliFone1": "994797882",
          "cliFone2": "995237836",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "LUCIANO DA MOTTA PINTO",
          "cliFantasia": "LUCIANO DA MOTTA PINTO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "000.435.152-58",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1141,
          "cliCep": "",
          "cliFone1": "995023235",
          "cliFone2": "992551110",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "FRANCISCO WASHINGTON AMORIM DE OLIVEIRA",
          "cliFantasia": "FRANCISCO WASHINGTON AMORIM DE OLIVEIRA",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "006.830.162-61",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1145,
          "cliCep": "",
          "cliFone1": "994882298",
          "cliFone2": "995203300",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "LAZARO ALEXANDRE DA SILVA PINHEIRO",
          "cliFantasia": "LAZARO ALEXANDRE DA SILVA PINHEIRO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "737.017.502-20",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1146,
          "cliCep": "",
          "cliFone1": "992553223",
          "cliFone2": "993377818",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "ELESSANDRA NASCIMENTO SOARES",
          "cliFantasia": "ELESSANDRA NASCIMENTO SOARES",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "609.286.972-15",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": "FERNANDA.LOPES 09/07/2019 12:50 - INFORMAÇÃO ASAAS\r\n---------------------------------------------------\r\nFERNANDA.LOPES 09/07/2019 12:50 - APARELHO RETIRADO NO DIA 22/06/2018  CLIENTE NÃO INCLUSO NO SISTEMA CDL- MOTIVO: SEM CLAUSULA CONTRATUAL"
        },
        {
          "cliCodigo": 1157,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "FRANCISCO GALDES BARROS",
          "cliFantasia": "FRANCISCO GALDES BARROS",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "112.181.262-72",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1159,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "DENNIS DE FREITAS PALACIO",
          "cliFantasia": "DENNIS DE FREITAS PALACIO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "834.415.732-34",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1206,
          "cliCep": "69097125",
          "cliFone1": "92993900143",
          "cliFone2": "9299849049",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "giankc8@gmail.com",
          "cliNome": "GIAN KARLO DA CRUZ",
          "cliFantasia": "GIAN KARLO DA CRUZ",
          "cliEndereco": "R Itaguaruna",
          "cliNumero": "38",
          "cliBairro": "Cidade Nova",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "720.638.582-68",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": "FERNANDA.LOPES 10/07/2019 14:50 - INFORMAÇÕES ASAAS- APARELHO RETIRADO DIA 11/08/2018 CLIENTE DEVENDO OS MESES MAIO, JUNHO, JULHO E AGOSTO CLIENTE INCLUSO NO SISTEMA CDL"
        },
        {
          "cliCodigo": 1219,
          "cliCep": "69095180",
          "cliFone1": "994644422",
          "cliFone2": "984117744",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "thayannabardia@gmail.com",
          "cliNome": "THAYANNA BARDIA PINHEIRO",
          "cliFantasia": "THAYANNA BARDIA PINHEIRO",
          "cliEndereco": "R Cel Francelino de Araújo",
          "cliNumero": "01",
          "cliBairro": "Cidade Nova",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "008.812.822-94",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": "FERNANDA.LOPES 17/06/2019 15:33 - INFORMAÇÃO ASAAS - CLIENTE TRANSFERIU CONTRATO PARA ORIELVES GOMES DIA 07/06"
        },
        {
          "cliCodigo": 1244,
          "cliCep": "69415000",
          "cliFone1": "92994393776",
          "cliFone2": "92993489164",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "rafaellvieira023@gmail.com",
          "cliNome": "RAFAEL DA CUNHA VIERIA",
          "cliFantasia": "RAFAEL DA CUNHA VIERIA",
          "cliEndereco": "São Paulo",
          "cliNumero": "25",
          "cliBairro": "São José",
          "cliCidade": "Iranduba",
          "cliEstado": "AM",
          "cliCnpjCpf": "025.848.442-06",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": "FERNANDA.LOPES 15/06/2019 17:19 - INFORMAÇÕES ASAAS -  CONTRATO TRANSFERIDO PARA DARLISON RIBEIRO CAMPOS DIA 02/08"
        },
        {
          "cliCodigo": 1416,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "TREINAMENTO",
          "cliFantasia": "TREINAMENTO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "000.000.000-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1489,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "ATENDIMENTO RAIO",
          "cliFantasia": "ATENDIMENTO RAIO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "000.000.000-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1490,
          "cliCep": "69086001",
          "cliFone1": "92991570137",
          "cliFone2": "92991171856",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "abraaaaaaaaaao@gmail.com",
          "cliNome": "ANGELINA GUIMARAES DE ALMEIDA",
          "cliFantasia": "ANGELINA GUIMARAES DE ALMEIDA",
          "cliEndereco": "Rua RIO CARAUARI",
          "cliNumero": "07",
          "cliBairro": "São José Operário",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "656.338.902-30",
          "cliComplemento": "EM FRENTE A PIZZARIA ROYAL",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1515,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "CLIENTES CONGELADOS",
          "cliFantasia": "CLIENTES CONGELADOS",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "000.000.000-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1546,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "USUARIOS TEMPORARIOS",
          "cliFantasia": "USUARIOS TEMPORARIOS",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "000.000.000-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1551,
          "cliCep": "69037520",
          "cliFone1": "92994937951",
          "cliFone2": "92993170246",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "cn6270377@gmail.com",
          "cliNome": "FRANCISCO TEIXEIRA DA COSTA",
          "cliFantasia": "FRANCISCO TEIXEIRA DA COSTA",
          "cliEndereco": "R Cinco de Setembro",
          "cliNumero": "60",
          "cliBairro": "Nova Esperança",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "575.433.482-68",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1557,
          "cliCep": "69060091",
          "cliFone1": "92992104231",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "izac156@gmail.com",
          "cliNome": "iza",
          "cliFantasia": "iza",
          "cliEndereco": "Bc Sta Cláudia",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "000.000.000-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 1923,
          "cliCep": "",
          "cliFone1": "",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "",
          "cliNome": "EVELYN MELO",
          "cliFantasia": "EVELYN MELO",
          "cliEndereco": "",
          "cliNumero": "",
          "cliBairro": "",
          "cliCidade": "",
          "cliEstado": "",
          "cliCnpjCpf": "000.000.000-00",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 2052,
          "cliCep": "69044-027",
          "cliFone1": "(45) 00000-0000",
          "cliFone2": "",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "ian.lemos@insidesistemas.com.br",
          "cliNome": "TESTE INTEGRAÇÃO",
          "cliFantasia": "",
          "cliEndereco": "RUA MAJIRONA",
          "cliNumero": "8",
          "cliBairro": "PLANALTO",
          "cliCidade": "MANAUS",
          "cliEstado": "AM",
          "cliCnpjCpf": "078.745.769-85",
          "cliComplemento": "",
          "cliGeoLatitude": -3.1190275,
          "cliGeoLongitude": -60.0217314,
          "cliListaChaves": "",
          "cliModalidade": "R",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 1,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "11/2/2018 12:00:00 AM",
          "dataAssinatura": "11/2/2018 12:00:00 AM",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "10/23/2018 12:00:00 AM",
          "observacao": ""
        },
        {
          "cliCodigo": 935,
          "cliCep": "69047660",
          "cliFone1": "085982229205",
          "cliFone2": "991133724",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "fabinhocoral@gmail.com",
          "cliNome": "FRANQUIMAR BRITO DE SOUZA JUNIOR",
          "cliFantasia": "FRANQUIMAR BRITO DE SOUZA JUNIOR",
          "cliEndereco": "R Pe Anchieta",
          "cliNumero": "172",
          "cliBairro": "Redenção",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "030.906.482-10",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        },
        {
          "cliCodigo": 220,
          "cliCep": "69084480",
          "cliFone1": "92991241446",
          "cliFone2": "92992154280",
          "cliCelular1": "",
          "cliCelular2": null,
          "cliEmail": "matheus1658@hotmail.com",
          "cliNome": "WILKENS PEIXOTO DOS SANTOS",
          "cliFantasia": "WILKENS PEIXOTO DOS SANTOS",
          "cliEndereco": "R Sete de Abril",
          "cliNumero": "92",
          "cliBairro": "Zumbi dos Palmares",
          "cliCidade": "Manaus",
          "cliEstado": "AM",
          "cliCnpjCpf": "054.249.332-20",
          "cliComplemento": "",
          "cliGeoLatitude": 0.0,
          "cliGeoLongitude": 0.0,
          "cliListaChaves": "",
          "cliModalidade": "",
          "cliStatusFaturamento": false,
          "cliTipo": "C",
          "cliAtivo": true,
          "empresaFaturamento": 0,
          "formaPagamento": "",
          "situacao": "Bom Pagador",
          "numContrato": "",
          "dataImpressao": "",
          "dataAssinatura": "",
          "dataTerminoContrato": "",
          "tipoFaturamento": "GERAL",
          "dataCadastro": "",
          "observacao": ""
        }
    ]
};

Mock.onGet("/listar_clientes").reply((config) => {
    return [200, clientDB.clients];
});
