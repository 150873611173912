import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const Planos = Loadable(lazy(() => import('./Planos')));

const planosRoutes = [
  { path: '/planos', element: <Planos /> },
];

export default planosRoutes;
