import { useState, useEffect } from 'react';
import InsideApi from '../services/InsideApi';
import ClientesContext from '../contexts/ClientesContext';

const ClientesContextProvider = ({ children }) => {
    const [ clientes, setClientes ] = useState([]);
    const [ chavePesquisa, setChavePesquisa ] = useState('');

    const getClientes = async () => {
        const res = await InsideApi.listarClientes();
        setClientes(res);
    }

    // useEffect(() => {
    //     getClientes();
    // }, []);

    return (
        <ClientesContext.Provider value={{ clientes, getClientes, setClientes, chavePesquisa, setChavePesquisa }}>
            {children}
        </ClientesContext.Provider>
    );
};

export default ClientesContextProvider;