import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaPedidosDeLicencas = Loadable(lazy(() => import('./ListaPedidosDeLicencas')));
const FormularioPedidosDeLicencas = Loadable(lazy(() => import('./FormularioPedidosDeLicencas')));

const pedidosDeLicencasRoutes = [
    { path: '/pedidos-de-licencas', element: <ListaPedidosDeLicencas /> },
    { path: '/pedidos-de-licencas/new', element: <FormularioPedidosDeLicencas /> },
    { path: '/pedidos-de-licencas/:id', element: <FormularioPedidosDeLicencas /> },
];

export default pedidosDeLicencasRoutes;