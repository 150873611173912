import { lazy } from 'react';
import Loadable from 'app/components/Loadable';
import BancoDeHoras from './BancoDeHoras';
import Faltas from './Faltas';
import Ocorrencias from './Ocorrencias';
import Licencas from './Licensas';

const HorasTrabalhadas = Loadable(lazy(() => import('./HorasTrabalhadas')));

const relatoriosRoutes = [
  { path: '/relatorios/horas-trabalhadas', element: <HorasTrabalhadas /> },
  { path: '/relatorios/banco-de-horas', element: <BancoDeHoras /> },
  { path: '/relatorios/faltas', element: <Faltas /> },
  { path: '/relatorios/ocorrencias', element: <Ocorrencias /> },
  { path: '/relatorios/licencas', element: <Licencas /> },
];

export default relatoriosRoutes;
