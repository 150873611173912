import styled from '@emotion/styled';
import { Autocomplete, Button, Card, CardContent, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Breadcrumb } from 'app/components'
import { AlertContext } from 'app/contexts/AlertContext';
import api from 'app/services/api';
import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Settings } from '@mui/icons-material';
dayjs.extend(customParseFormat);

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
}));

const BancoDeHoras = () => {
    const [dadosRelatorio, setDadosRelatorio] = useState({})
    const [empresas, setEmpresas] = useState([])
    const [departamentos, setDepartamentos] = useState([])
    const [cargos, setCargos] = useState([])
    const [colaboradores, setColaboradores] = useState([])
    const [setores, setSetores] = useState([])
    const [loading, setLoading] = useState(false)
    const { setAlert } = useContext(AlertContext);

    useEffect(() => {
        loadEmpresas()
        loadDepartamentos()
        loadCargos()
        loadColaboradores()
        loadSetores()
    }, [])

    const gerarRelatorio = async () => {
        try {
            setLoading(true)
            const response = await api.get('/Relatorios/BancoDeHoras', {
                params: {
                    data_inicio: dadosRelatorio.data_inicio?.format('YYYY-MM-DD'),
                    data_fim: dadosRelatorio.data_fim?.format('YYYY-MM-DD'),
                    id_empresa: dadosRelatorio.id_empresa,
                    id_departamento: dadosRelatorio.id_departamento,
                    id_cargo: dadosRelatorio.id_cargo,
                    id_colaborador: dadosRelatorio.id_colaborador
                }
            })

            if (response.data?.pdf) {
                window.open(response.data.pdf, '_blank')
            } else {
                throw new Error('Erro ao gerar relatório. Tente novamente mais tarde.')
            }

        } catch (e) {
            setAlert({ open: true, message: e.response?.data?.message || 'Erro ao gerar relatório.', type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    const loadDepartamentos = async () => {
        try {
            const response = await api.get('/Departamentos?pageSize=999')
            setDepartamentos(response.data.itens)
        } catch (e) {
            console.error(e)
        }
    }

    const loadSetores = async () => {
        try {
            const response = await api.get('/Setores?pageSize=999')
            setSetores(response.data.itens)
        } catch (e) {
            console.error(e)
        }
    }

    const loadEmpresas = async () => {
        try {
            const response = await api.get('/Empresas?pageSize=999')
            setEmpresas(response.data.itens)
        } catch (e) {
            console.error(e)
        }
    }

    const loadCargos = async () => {
        try {
            const response = await api.get('/Cargos?pageSize=999')
            setCargos(response.data.itens)
        } catch (e) {
            console.error(e)
        }
    }

    const loadColaboradores = async () => {
        try {
            const response = await api.get('/Colaboradores?pageSize=999')
            setColaboradores(response.data.itens)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (dadosRelatorio.data_inicio && dadosRelatorio.data_fim && dayjs(dadosRelatorio.data_inicio).isAfter(dadosRelatorio.data_fim)) {
            setAlert({ open: true, message: 'Data de início não pode ser maior que a data de fim.', type: 'error' })
            setDadosRelatorio({ ...dadosRelatorio, data_fim: null })
        }

        console.log(dayjs(dadosRelatorio.data_inicio).diff(dadosRelatorio.data_fim, 'days'))
        if (dadosRelatorio.data_inicio && dadosRelatorio.data_fim && dayjs(dadosRelatorio.data_inicio).diff(dadosRelatorio.data_fim, 'days') < -31) {
            setAlert({ open: true, message: 'Intervalo de datas não pode ser maior que 31 dias.', type: 'error' })
            setDadosRelatorio({ ...dadosRelatorio, data_fim: null })
        }

    }, [dadosRelatorio.data_inicio, dadosRelatorio.data_fim])

    return (
        <Container>
            <Breadcrumb
                routeSegments={[
                    { name: 'Relatório de Banco de Horas' },
                ]}
            />

            <Card
                sx={{
                    mt: 2,
                    p: 2
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Relatório de Banco de Horas
                </Typography>
                <Divider />

                <CardContent sx={{ px: 0 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                <DesktopDatePicker
                                    label="Data de Início"
                                    inputFormat="DD/MM/YYYY"
                                    value={dadosRelatorio?.data_inicio ?? null}
                                    onChange={(newValue) => {
                                        setDadosRelatorio({ ...dadosRelatorio, data_inicio: newValue })
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth sx={{ mt: 2 }} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                <DesktopDatePicker
                                    label="Data de Fim"
                                    inputFormat="DD/MM/YYYY"
                                    value={dadosRelatorio?.data_fim ?? null}
                                    onChange={(newValue) => {
                                        setDadosRelatorio({ ...dadosRelatorio, data_fim: newValue })
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth sx={{ mt: 2 }} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                fullWidth sx={{ mt: 2 }}
                                disablePortal
                                options={colaboradores.map((colaborador) => ({ label: colaborador.nome, value: colaborador.id_colaborador }))}
                                renderInput={(params) => <TextField {...params} label="Colaborador" />}
                                onChange={(e, newValue) => {
                                    setDadosRelatorio({ ...dadosRelatorio, id_colaborador: newValue?.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel>Loja</InputLabel>
                                <Select
                                    value={dadosRelatorio?.id_empresa ?? ''}
                                    label="Loja"
                                    onChange={(e) => {
                                        setDadosRelatorio({ ...dadosRelatorio, id_empresa: e.target.value })
                                    }}
                                >
                                    {empresas.map((empresa) => (
                                        <MenuItem key={empresa.id_empresa} value={empresa.id_empresa}>{empresa.nome}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel>Departamento</InputLabel>
                                <Select
                                    value={dadosRelatorio?.id_departamento ?? ''}
                                    label="Departamento"
                                    onChange={(e) => {
                                        setDadosRelatorio({ ...dadosRelatorio, id_departamento: e.target.value })
                                    }}
                                >
                                    {departamentos.map((departamento) => (
                                        <MenuItem key={departamento.id_departamento} value={departamento.id_departamento}>{departamento.descricao}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel>Setor</InputLabel>
                                <Select
                                    value={dadosRelatorio?.id_setor ?? ''}
                                    label="Setor"
                                    onChange={(e) => {
                                        setDadosRelatorio({ ...dadosRelatorio, id_setor: e.target.value })
                                    }}
                                >
                                    {setores.map((setor) => (
                                        <MenuItem key={setor.id_setor} value={setor.id_setor}>{setor.nome}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel>Cargo</InputLabel>
                                <Select
                                    value={dadosRelatorio?.id_cargo ?? ''}
                                    label="Cargo"
                                    onChange={(e) => {
                                        setDadosRelatorio({ ...dadosRelatorio, id_cargo: e.target.value })
                                    }}
                                >
                                    {cargos.map((cargo) => (
                                        <MenuItem key={cargo.id_cargo} value={cargo.id_cargo}>{cargo.nome}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider sx={{ mt: 5 }} />
                <Button
                    sx={{ mt: 2, mr: 2 }}
                    onClick={() => {
                        gerarRelatorio()
                    }}
                    color="primary"
                    variant="contained"
                    disabled={loading}
                >
                    <Settings />
                    <Typography variant="button" sx={{ ml: 1 }}>GERAR RELATÓRIO</Typography>
                </Button>
            </Card>
        </Container>
    )
}

export default BancoDeHoras