import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const GerenciamentoDePonto = Loadable(lazy(() => import('./GerenciamentoDePonto')));

const gerenciamentoDePontoRoutes = [
  { path: '/gerenciamento-de-ponto', element: <GerenciamentoDePonto /> },
];

export default gerenciamentoDePontoRoutes;
