import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const MonitorAtendimentos = Loadable(lazy(() => import('./MonitorAtendimentos')));
const MeusAtendimentos = Loadable(lazy(() => import('./MeusAtendimentos')));
const Atender = Loadable(lazy(() => import('./Atender')));
const EmitirSenha = Loadable(lazy(() => import('./EmitirSenha')));
const Comercial = Loadable(lazy(() => import('./Comercial')));
const Supervisao = Loadable(lazy(() => import('./Supervisao')));
const Instalacao = Loadable(lazy(() => import('./Instalacao')));

const atendimentosRoutes = [
    { path: '/monitor-atendimentos', element: <MonitorAtendimentos /> },
    { path: '/meus-atendimentos', element: <MeusAtendimentos /> },
    { path: '/atender/:id', element: <Atender /> },
    { path: '/emitir-senha', element: <EmitirSenha /> },
    { path: '/comercial', element: <Comercial /> },
    { path: '/supervisao', element: <Supervisao /> },
    { path: '/instalacao', element: <Instalacao /> },
];

export default atendimentosRoutes;
