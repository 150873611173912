import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaHorarios = Loadable(lazy(() => import('./ListaHorarios')));
const FormularioHorarios = Loadable(lazy(() => import('./FormularioHorarios')));

const horariosRoutes = [
    { path: '/horarios', element: <ListaHorarios /> },
    { path: '/horarios/new', element: <FormularioHorarios /> },
    { path: '/horarios/:id', element: <FormularioHorarios /> },
];

export default horariosRoutes;