import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const Tecnicos = Loadable(lazy(() => import('./Tecnicos')));
const Tecnico = Loadable(lazy(() => import('./Tecnico')));

const tecnicosRoutes = [
  { path: '/tecnicos', element: <Tecnicos /> },
  { path: '/tecnico', element: <Tecnico /> },
  { path: '/tecnico/:id', element: <Tecnico /> }
];

export default tecnicosRoutes;
