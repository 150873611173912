import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaCargos = Loadable(lazy(() => import('./ListaCargos')));
const FormularioCargos = Loadable(lazy(() => import('./FormularioCargos')));

const cargosRoutes = [
    { path: '/cargos', element: <ListaCargos /> },
    { path: '/cargos/new', element: <FormularioCargos /> },
    { path: '/cargos/:id', element: <FormularioCargos /> },
];

export default cargosRoutes;