import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaLancamentos = Loadable(lazy(() => import('./ListaLancamentos')));
const FormularioLancamentos = Loadable(lazy(() => import('./FormularioLancamentos')));

const lancamentosRoutes = [
    { path: '/lancamentos', element: <ListaLancamentos /> },
    { path: '/lancamentos/new', element: <FormularioLancamentos /> },
    { path: '/lancamentos/:id', element: <FormularioLancamentos /> },
];

export default lancamentosRoutes;