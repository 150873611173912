const baseUrl = process.env.REACT_APP_ENV === 'production' ? 'https://api.raiozeus.com.br' : (process.env.REACT_APP_ENV === 'test' ? 'https://api-hml.raiozeus.com.br' : process.env.REACT_APP_BASE_URL);

export const configuracao = {
  app: {
    chave: process.env.REACT_APP_APP_KEY,
    ambiente: process.env.REACT_APP_ENV 
  },
  api: {
    baseUrl: baseUrl
  }
};
