import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaPedidosDeBancosDeHoras = Loadable(lazy(() => import('./ListaPedidosDeBancosDeHoras')));
const FormularioPedidosDeBancosDeHoras = Loadable(lazy(() => import('./FormularioPedidosDeBancosDeHoras')));

const pedidosDeBancosDeHorasRoutes = [
    { path: '/pedidos-de-bancos-de-horas', element: <ListaPedidosDeBancosDeHoras /> },
    { path: '/pedidos-de-bancos-de-horas/new', element: <FormularioPedidosDeBancosDeHoras /> },
    { path: '/pedidos-de-bancos-de-horas/:id', element: <FormularioPedidosDeBancosDeHoras /> },
];

export default pedidosDeBancosDeHorasRoutes;